
#Registerbutton{
    background-color:var(--buttoncolor) ;
    text-align: center;
    padding:10px 10px 10px 10px;
    color: #fff;
    font-family: var(--DefaultFont);
    font-size: 16px;
   
    width: 100px;
    height: 35px;
    border-radius: 5px;
border: #0668CB;

}
#Events{
    display: flex;
    flex-direction: row;
    gap:5%;
    padding: 2%;
    overflow-x: hidden;
}
#Currentevents{
   background-color: var(--containerColor);
    width: 70%;
    display: flex;
    flex-direction: column;
    padding: 2%;
    border-radius: 10px;
    
   
   
}
#Currentevents h2{
text-align: center;
}
.Description{
    width: 60%;
    margin-bottom: 20px;
}
.Upcomingevents{
    display: flex;
    flex-direction: row;
    gap:10%;
margin-bottom: 5%;
padding-top: 20px;
height: max-content;
   
}

.Upcomingevents p{
    font-size: var(--eventfont);
}

.Eventsimg img {
    max-width: 100%;
    height:auto;
    border-radius: 5%;
}

#Previousevents{
    background-color: var(--containerColor);
    display: flex;
    flex-direction: column;
    border-radius: 10px;
        text-align: center;
    padding: 2%;
   width: 25%;
    

   
}
.imagecon{
    padding-top: 20px;
}
.Eventsimg:not(:last-child){
    margin-bottom: 20%;
}

@media (max-width: 1200px)
{
#Events{
    display: flex;
    flex-direction: column;
    margin-bottom: 10%;
    align-items: center;
    padding: 2%;

}

#Currentevents{
   background-color: var(--containerColor);
    width: 90%;
    display: flex;
    flex-direction: column;
    padding: 2%;
    border-radius: 10px;

   
}
.Description{
    width: 90%;
}
.Upcomingevents{
    display: flex;
    flex-direction: column;
   padding-bottom: 30px;
margin-bottom: 5%;
align-items: center;
text-align: center;
   
}

.Upcomingevents p{
    font-size: var(--eventfont);
}

.Eventsimg img {
    width: auto;
    height: 30vh;
    border-radius: 5%;
}

#Previousevents{
    background-color: var(--containerColor);
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    text-align: center;
    padding: 2%;
 width: 90%;
   

   
}
.Eventsimg:not(:last-child){
    margin-bottom: 10%;
}

}