@import url('https://fonts.googleapis.com/css2?family=League+Spartan&family=Montserrat:wght@700&family=PT+Sans&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=League+Spartan&family=Poppins&display=swap');

:root {
    /* COLORS */

    --backgroundColor: #090E13;
    --containerColor: #0F151C;
    --textColor:#aacbf4;
    --linkColor: #fff;

    --primaryColor: #0668CB;
    --secondaryColor: #1DCEEF;
    --complementaryColor: rgb(255, 255, 148);

    

    --darkText: ;
    --lightText: rgba(57, 82, 107, 1);


    

    /* Font Families */
    --DefaultFont: 'League Spartan', sans-serif;

    /* MISC */
    --borderRadius: 10px;
    --headingfont:32px;
    --contentfont:24px;
    --subheadingfont:24px;
    --eventfont:16px
    /* IMAGES */
    --Logo: url('');
    --buttoncolor:#0668CB;
    --buttonfont: #aacbf4;
}
#Teams {
    margin: 0;
    padding: 0;
   
  }
  
  
  
  #Teams{
   background-color: var(--backgroundColor);
   font-family: var(--DefaultFont);
  }
  
  li {
    list-style: none;
  }
  
  
  /* style member component */
  .list-members {
    background: var(--containerColor);
    width: 80%;
    margin-top: 10px;
   margin-bottom: 30px;
   margin-left: auto;
   margin-right: auto;
    display: flex;
    flex-wrap: wrap;
    border-radius: 20px;
  }
  
  .member {
    flex-basis: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .member-image {
    width: 50%;
    height: 100%;
    cursor: pointer;
    overflow: hidden;
    position: relative;
  }
  
  .member-image img {
   width: 100%;
   height: 100%;
    transition: 1s;
  }
  
  .member-image:hover img {
    transform: scale(1.1);
  }
  
  .member-info {
    width: 50%;
    text-align: center;
  }
  
  .member-info p {
    margin: 20px 0;
   
  }
  
  
  /* style social link */
  .social-link .fab {
    width: 35px;
    height: 35px;
    line-height:35px; 
    border: 1px solid #000;
    margin: 0 7px;
    cursor: pointer;
    transition: transform .5s;
    background-color: var(--buttoncolor);
  }
  
  .social-link .fab:hover {
    background: var(--containerColor);
    color:var(--buttoncolor);
    transform: translateY(-7px);
  }
  
  /* Membuat segitiga */
  .member-image::after {
    content: '';
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
 
    border-right: 15px solid  #0668CB ;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
  .social-link i{
   color: #fff;
   overflow: hidden;
  }
  
  
  /* Merubah posisi member-image dengan member-info */
  @media screen and (min-width: 771px) {
    .member:nth-child(4n+3) .member-info,
    .member:nth-child(4n+4) .member-info {
      order: 1;
    }
    .member:nth-child(4n+3) .member-image,
    .member:nth-child(4n+4) .member-image {
      order: 2;
    }
  
    /* Merubah posisi sigitiga pada baris genap */
    .member:nth-child(4n+3) .member-image::after,
    .member:nth-child(4n+4) .member-image::after {
      left: 0;
      right: auto;
      transform: translateY(-50%) rotateZ(180deg);
    }
  }
  
  
  /* Mobile Styles */
  @media screen and (max-width: 770px) {
    .list-members {
      width: 95%;
    }
    .member {
      flex-basis: 100%;
      font-size: 14px;
    }
    .social-link .fab {
      width: 30px;
      height: 30px;
      line-height: 30px;
    }
  
    .member:nth-child(even) .member-info {
      order: 1;
    }
    .member:nth-child(even) .member-image {
      order: 2;
    }
    
    /* Merubah posisi sigitiga elemen genap */
    .member:nth-child(even) .member-image::after {
      left: 0;
      right: auto;
      transform: translateY(-50%) rotateZ(180deg);
    }
  }
  @media screen and (max-width:1100px){
    .list-members{
        width: 90%;
        
    }
    .member-info p{
        font-size: 16px;
        padding: 5px;
    }
    .member-info h3{
      font-size: 18px;
      padding: 5px;
    }
  }