

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th, td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

th {
  background-color:var(--backgroundColor);
}

  /* src/StartupTable.css */
.table-container {

  font-family: "League Spartan", sans-serif; /* Use League Spartan font */
  background-color: var(--containerColor);
  padding: 2%;
  border-radius: var(--borderRadius);

}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  color: white; /* Text color is white */
}

th, td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  background-color: var(--containerColor);
}
th{
  color: #aacbf4;
  font-weight: 900;
  font-size: 18px;
}
td{
  color:white;
}

@media (max-width:992px) {
  .table-container{
    padding: 1%;
    width: 100%;
font-size: 6px;  }
th{
  font-size: 8px;
}
 
}
