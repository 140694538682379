@import url('https://fonts.googleapis.com/css2?family=League+Spartan&family=Montserrat:wght@700&family=PT+Sans&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=League+Spartan&family=Poppins&display=swap');

:root {
    /* COLORS */

    --backgroundColor: #090E13;
    --containerColor: #0F151C;
    --textColor:#aacbf4;
    --linkColor: #fff;

    --primaryColor: #0668CB;
    --secondaryColor: #1DCEEF;
    --complementaryColor: rgb(255, 255, 148);

    

    --darkText: ;
    --lightText: rgba(57, 82, 107, 1);


    

    /* Font Families */
    --DefaultFont: 'League Spartan', sans-serif;

    /* MISC */
    --borderRadius: 10px;
    --headingfont:32px;
    --contentfont:24px;
    --subheadingfont:24px;
    --eventfont:16px
    /* IMAGES */
    --Logo: url('');
    --buttoncolor:#0668CB;
    --buttonfont: #aacbf4;
}
*{
    margin: 0;
    padding: 0;
  overflow-x: hidden;
    
}
body{
    background-color:var(--backgroundColor);
    overflow-x: hidden;
    overflow: hidden;
}
a, button, .btn {
  transition: 0.3s all !important;
}




section{
  padding-top: 2%;
  padding-bottom:5%;
}

h2{
  font-size: var(--headingfont);
  font-weight: 900;
  color: var(--linkColor);
  font-family: var(--DefaultFont);
}
h3{
  font-size:  var(--subheadingfont);
  font-weight: 600;
  color: #fff;
  font-family: var(--DefaultFont);
}
h1,h2,h3,h4,h5,h6 {
  color: white;
}
p{
  font-size: var(--contentfont);
  font-weight: 400;
  color: var(--textColor);
  font-family: var(--DefaultFont);

}




.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



@media (max-width: 786px) {
  
  .footer .container {
      flex-direction: column !important;
      align-items: center;
  }

}